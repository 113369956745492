import styled from 'styled-components'
import { Link } from 'gatsby-plugin-intl'
import Checkbox from 'src/components/atoms/Checkbox'
import { breakpoint } from 'src/theme/grid'

export const PanelTitle = styled.h2`
  color: ${({ theme }) => theme.colors.ebonyClay};
  font-size: 1.5rem;
  line-height: 1.38;
  font-weight: 600;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-bottom: 1.2rem;
`

export const PanelSubtitle = styled.h4`
  color: ${({ theme }) => theme.colors.corduroy.hex()};
  font-size: 1rem;
  line-height: 1.38;
  font-weight: 400;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-bottom: 1.6rem;
`

export const PanelAdditionalInformation = styled.span`
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  line-height: 1.36;
  font-size: 0.95rem;
  width: 100%;
  text-align: center;
  display: inline-block;
  a {
    text-decoration: underline;
    font-weight: 600;
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.easternBlue.hex()};
  font-size: 1rem;
  line-height: 1.38;
  box-sizing: border-box;
`

export const SectionTitle = styled.span`
  color: ${({ theme }) => theme.colors.apple.hex()};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  margin-bottom: 1.2rem;
`

export const Condition = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.corduroy.hex()};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1.2rem;
  ${breakpoint.m`
    margin-bottom: 0.2rem;
  `}
  a {
    color: ${({ theme }) => theme.colors.easternBlue.hex()};
  }
  align-items: center;
  ${Checkbox} {
    margin-right: 1.8rem;
  }
`

export const Address = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.4;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.ebonyClay.hex()};
  margin: 1.2rem 0 2.4rem 0;
  a {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    font-weight: 600;
  }
  span {
    width: 100%;
  }
`

export const Spacer = styled.span`
  display: flex;
  width: 60px !important;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.apple.hex()};
  margin: 0.8rem 0;
`

export const ErrorMessage = styled.span`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.colors.error.hex()};
  margin-bottom: 1.2rem;
  font-size: 1rem;
`
