import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { Button } from 'src/components/atoms/Buttons'

const SuccessDialog = ({ show, title, message, buttonText, onConfirm }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    show && setOpen(true)
  }, [show])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button active onClick={onConfirm} color='primary' autoFocus>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SuccessDialog
