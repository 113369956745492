import { connect } from 'react-redux'

import {
  loginUserRoutine,
  logoutUserRoutine,
  setRegistrationValueRoutine,
  passwordResetRequestRoutine,
  setNewPasswordRoutine,
  loginIfSessionExistsRoutine,
  registerUserRoutine,
  submitContactFormRoutine
} from './actions'

import {
  selectRegistrationData,
  selectForgotPasswordStatus,
  selectForgotPasswordError,
  selectLoginData,
  selectForgotPasswordSentSuccessfully,
  selectPasswordChangedSuccessfully
} from './selectors'

const mapStateToProps = state => ({
  registration: selectRegistrationData(state),
  forgotPasswordState: selectForgotPasswordStatus(state),
  forgotPasswordError: selectForgotPasswordError(state),
  login: selectLoginData(state),
  forgotPasswordSentSuccessfully: selectForgotPasswordSentSuccessfully(state),
  changedSuccessfully: selectPasswordChangedSuccessfully(state)
})

const mapDispatchToProps = dispatch => ({
  loginUser: (username, password) =>
    dispatch(loginUserRoutine({ username, password })),
  passwordResetRequest: username =>
    dispatch(passwordResetRequestRoutine({ username })),
  setNewPassword: (token, password, username, confirmPassword) =>
    dispatch(
      setNewPasswordRoutine({ token, password, username, confirmPassword })
    ),
  logoutUser: () => dispatch(logoutUserRoutine()),
  setRegistrationValue: (name, value) =>
    dispatch(setRegistrationValueRoutine({ name, value })),
  loginIfSessionExists: () => dispatch(loginIfSessionExistsRoutine()),
  registerUser: () => dispatch(registerUserRoutine())
})

export default connect(mapStateToProps, mapDispatchToProps)

export const connectContactForm = connect(null, dispatch => ({
  submitContactForm: payload => dispatch(submitContactFormRoutine(payload))
}))
