import styled from 'styled-components'
import { breakpoint } from 'src/theme/grid'

export const Panel = styled.div`
  height: 100%;
  width: 100%;
  min-height: ${({ theme }) =>
    `calc(100vh - ${theme.dimensions.topBarHeight})`};
  max-width: 560px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 3.2% 6% 2.6rem;
  ${breakpoint.s`
    padding: 3.2% 5.2rem 2.6rem;
  `}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 0.8rem;
  box-sizing: border-box;
  justify-content: ${({ align }) => align || 'flex-start'};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  box-sizing: border-box;
  justify-content: ${({ align }) => align || 'flex-start'};
`
