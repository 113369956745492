import React from 'react'
import styled from 'styled-components'

const CheckBoxComponent = ({ className, name, value, onChange, option }) => {
  const handleChange = (n, v) => onChange(n, v)

  return (
    <div className={className}>
      <CheckBox>
        <input
          type='checkbox'
          name={name}
          value={option.value}
          checked={value === option.value}
          onChange={() => handleChange(name, option.value)}
        />
        <span>{option.text}</span>
      </CheckBox>
    </div>
  )
}

const CheckBox = styled.label`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  input {
    display: none;
    & + span {
      line-height: 22px;
      height: 22px;
      display: block;
      position: relative;
      margin: 0.6rem;
      &:before,
      &:after {
        content: '';
        width: 22px;
        height: 22px;
        display: block;
        border-radius: 2px;
        left: 50%;
        top: 0;
        position: absolute;
      }
      &:before {
        background: ${({ theme }) => theme.colors.gallery.hex()};
        transition: background 0.2s ease,
          transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 2);
      }
      &:after {
        background: white;
        transform: scale(0.78);
        transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.4);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(1.04);
        background: ${({ theme }) => theme.colors.apple.hex()};
      }
      &:after {
        transform: scale(0.4);
        transition: transform 0.3s ease;
      }
    }
  }
  &:hover {
    input {
      & + span {
        &:before {
          transform: scale(0.92);
        }
        &:after {
          transform: scale(0.74);
        }
      }
      &:checked + span {
        &:after {
          transform: scale(0.4);
        }
      }
    }
  }
`

export default styled(CheckBoxComponent)`
  display: flex;
  justify-content: space-around;
`
