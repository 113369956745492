import React from 'react'
import ThemeProvider from 'src/theme/ThemeProvider'
import Layout from 'src/theme/Layout'
import ForgottenPassword from 'src/features/auth/ForgottenPassword'

export default () => (
  <ThemeProvider>
    <Layout>
      <ForgottenPassword />
    </Layout>
  </ThemeProvider>
)
