import { string, object, ref, boolean } from 'yup'

import {
  validate,
  validateDependantFields,
  validateSchema
} from 'src/ducks/schema'
import { MIN_TAXID_LENGTH, LEAD_SOURCE_OPTIONS } from 'src/ducks/consts'

const loginSchema = object().shape({
  username: string().required(),
  password: string().required()
})

const forgotPasswordSchema = object().shape({
  username: string()
    .required()
    .nullable()
})

const newPasswordSchema = object().shape({
  password: string().required(),
  confirmPassword: string()
    .required()
    .oneOf([ref('password')], 'validation.same')
})

const signupSchema = object().shape({
  regulationsAccepted: boolean()
    .oneOf([true], 'validation.required')
    .required(),
  subscriptionType: string()
    .required()
    .oneOf(
      [
        'carrier_basic',
        'carrier_pro',
        'forwarder_basic',
        'forwarder_pro',
        'carrier_forwarder_basic',
        'carrier_forwarder_pro'
      ],
      'validation.required'
    ),
  firstName: string().required(),
  lastName: string().required(),
  username: string().required(),
  email: string()
    .email()
    .required(),
  phoneNumber: string()
    .matches(/^$|^\+\d{9,20}$/, 'validation.phoneNumber')
    .required(),
  businessProfile: string()
    .required()
    .oneOf(
      ['carrier', 'forwarder', 'carrier_forwarder'],
      'validation.required'
    ),
  taxId: string()
    .min(MIN_TAXID_LENGTH)
    .required(),
  companyName: string().required(),
  address: string().required(),
  postalCode: string()
    .min(4)
    .required(),
  country: string().required(),
  city: string().required(),
  additionalInfo: string(),
  vehiclesCount: string(),
  leadSourceSelect: string().required(),
  leadSourceOtherDetails: string().when('leadSourceSelect', {
    is: LEAD_SOURCE_OPTIONS.OTHER,
    then: string().required()
  }),
  leadSourceAffiliateProgramDetailsFirstAndLastName: string().when(
    'leadSourceSelect',
    {
      is: LEAD_SOURCE_OPTIONS.AFFILIATE_PROGRAM,
      then: string().required()
    }
  ),
  leadSourceAffiliateProgramDetailsPhone: string().when('leadSourceSelect', {
    is: LEAD_SOURCE_OPTIONS.AFFILIATE_PROGRAM,
    then: string().required()
  }),
  leadSourceAffiliateProgramDetailsCompany: string().when('leadSourceSelect', {
    is: LEAD_SOURCE_OPTIONS.AFFILIATE_PROGRAM,
    then: string().required()
  })
})

const contactSchema = object().shape({
  regulationsAccepted: boolean()
    .oneOf([true], 'validation.required')
    .required(),
  name: string().required(),
  email: string()
    .email()
    .required(),
  message: string()
    .min(5)
    .required()
})

//login
export const validateLogin = validate(loginSchema)
export const validateLoginSchema = validateSchema(loginSchema)

//signup
export const validateSignup = validate(signupSchema)
export const validateSignupSchema = validateSchema(signupSchema)

//forgot password
export const validateForgotPassword = validate(forgotPasswordSchema)
export const validateForgotPasswordSchema = validateSchema(forgotPasswordSchema)
export const validateNewPassword = validate(newPasswordSchema)
export const validateNewPasswordSchema = validateSchema(newPasswordSchema)
export const validateRepeatPassword = validateDependantFields(newPasswordSchema)

//contact
export const validateContact = validate(contactSchema)
export const validateContactSchema = validateSchema(contactSchema)
