import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, FormattedMessage, useIntl, navigate } from 'gatsby-plugin-intl'

import routes from 'src/utils/routes'
import TextInput from 'src/components/atoms/TextInput'
import { Button } from 'src/components/atoms/Buttons'
import { API_STATES } from 'src/ducks/consts'
import useValidateSchema from 'src/hooks/useValidateSchema'
import SuccessDialog from 'src/features/auth/components/SuccessDialog'
import {
  validateForgotPasswordSchema,
  validateForgotPassword
} from './duck/schema'
import connect from './duck/connectors'
import {
  PanelTitle,
  PanelSubtitle,
  PanelAdditionalInformation,
  ErrorMessage
} from './components/typography'
import { Panel, Row, Column } from './components/atoms'

const ForgottenPassword = ({
  className,
  passwordResetRequest,
  forgotPasswordState,
  forgotPasswordError,
  forgotPasswordSentSuccessfully
}) => {
  const [state, setState] = useState({ username: '' })
  const intl = useIntl()
  const valid = useValidateSchema(state, validateForgotPasswordSchema)

  const handleSubmit = e => {
    e.preventDefault()
    active && passwordResetRequest(state.username)
  }
  const active = forgotPasswordState !== API_STATES.IN_PROGRESS && valid

  return (
    <div className={className}>
      <PanelStyled>
        <Column>
          <PanelTitle>
            <FormattedMessage id='forgottenPassword.title' />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage id='forgottenPassword.subtitle' />
          </PanelSubtitle>
          <form onSubmit={handleSubmit}>
            <LoginRow>
              <TextInput
                label={intl.formatMessage({ id: 'common.user' })}
                id='username'
                name='username'
                placeholder={intl.formatMessage({ id: 'common.user' })}
                value={state.username}
                onChange={(name, value) =>
                  setState({ ...state, [name]: value })
                }
                validate={validateForgotPassword}
              />
            </LoginRow>
            <Button active={active} onClick={handleSubmit}>
              <FormattedMessage id='forgottenPassword.sendRequest' />
            </Button>
          </form>
          {forgotPasswordError && (
            <ErrorMessage>{forgotPasswordError}</ErrorMessage>
          )}
        </Column>
        <PanelAdditionalInformation>
          <FormattedMessage id='forgottenPassword.dontHaveAccountYet' />{' '}
          <Link to={routes.SIGN_UP}>
            <FormattedMessage id='common.register' />
          </Link>
        </PanelAdditionalInformation>
      </PanelStyled>
      <SuccessDialog
        show={forgotPasswordSentSuccessfully}
        title={<FormattedMessage id='forgottenPassword.successTitle' />}
        message={<FormattedMessage id='forgottenPassword.successMessage' />}
        buttonText='OK'
        onConfirm={() => {
          navigate(routes.HOME)
        }}
      />
    </div>
  )
}

const StyledForgottenPassword = styled(ForgottenPassword)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${Button} {
    width: 100%;
  }
`

const PanelStyled = styled(Panel)`
  padding-top: 2.6rem;
`

const LoginRow = styled(Row)`
  margin-bottom: 1.6rem;
`

export default connect(StyledForgottenPassword)
